.landing-page {
  background-color: #070312;
  color: white;
  font-family: Arial, sans-serif;
}

/* Beta Banner */
.beta-banner {
  background-color: #FFD700;
  color: #070312;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.hero-section {
  text-align: center;
  padding: 5rem 2rem;
  position: relative;
  overflow: hidden;
  background-image: url('../public/background2.png');
  background-size: cover;
  background-position: center;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(7,3,18,0.7) 0%, rgba(7,3,18,1) 100%);
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
}

.logo-pill-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.logo {
  width: 5rem;
  height: 5rem;
  margin-bottom: 1rem;
  filter: drop-shadow(0 0 10px rgba(211,47,83,0.5));
}

.pill {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFD700;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.pill:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

h1 {
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.tagline {
  color: #D32F53;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.description {
  max-width: 30rem;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.app-store-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.app-store-button img {
  height: 40px;
  transition: transform 0.3s ease;
}

.app-store-button:hover img {
  transform: translateY(-2px);
}

.features-container {
  padding: 2rem 5%;
}

.feature-section {
  display: flex;
  align-items: center;
  padding: 5rem;
  margin-bottom: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.feature-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.feature-section.reverse {
  flex-direction: row-reverse;
}

.feature-content {
  flex: 1;
  padding: 2rem;
}

.feature-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.feature-content p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.feature-image {
  flex: 0 0 auto;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-image img {
  max-width: 250px;
  max-height: 500px;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.feature-image img:hover {
  transform: scale(1.05);
}

.cta-section {
  text-align: center;
  padding: 5rem;
  background-color: #070312;
  border-radius: 15px;
  margin: 2rem 5%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

footer {
  text-align: center;
  padding: 2rem;
  font-size: 0.875rem;
  color: #888;
  background-color: #070312;
}

footer p {
  margin-bottom: 0.5rem;
}

footer a {
  color: #D32F53;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #FF6B81;
  text-decoration: underline;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.hero-section, .feature-section, .cta-section {
  animation: fadeIn 0.5s ease-out;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 3rem 1rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  .tagline {
    font-size: 1.2rem;
  }

  .feature-section, .feature-section.reverse {
    flex-direction: column;
    padding: 3rem 1.5rem;
  }

  .feature-content, .feature-image {
    width: 100%;
  }

  .feature-image img {
    max-width: 200px;
    max-height: 400px;
  }

  .features-container, .cta-section {
    padding: 2rem;
    margin: 1rem;
  }

  .app-store-buttons {
    flex-direction: column;
    align-items: center;
  }
}